@import "~src/ui/constant";

// Subscribe plan ----
.SubscribePlan {
  .Plan {
    @media (min-width: $breakpoint-md) {
      width: 20rem;
    }
  }
}
