@import "~src/ui/constant";

// License Activation ----
.LicenseActivation {
  &Alert {
    margin-bottom: 1rem !important;
  }

  &Input {
    @media (min-width: $breakpoint-md) {
      width: 25rem !important;
    }
  }

  &Button {
    width: 100%;
    margin-top: 1.25rem;

    @media (min-width: $breakpoint-md) {
      width: 25rem;
    }
  }
}
