@import "~src/ui/constant";

// Account ----
.Account {
  flex-grow: 1;
}

// Item ----
.Item {
  @media (min-width: $breakpoint-md) {
    display: flex;
  }

  &Title {
    margin-bottom: 0.5rem;

    @media (min-width: $breakpoint-md) {
      width: 25%;
      margin-bottom: 0;
    }

    span {
      font-weight: 500;

      &::before {
        margin-right: 0.75rem;
      }
    }
  }

  &Value {
    margin-bottom: 1rem;

    @media (min-width: $breakpoint-md) {
      margin-bottom: 0;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        display: flex;
        flex-direction: column;
        list-style: none;
        margin-top: 1rem;

        a {
          font-weight: 500;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        span {
          display: block;
          margin-top: 0.25rem;
          font-size: $font-size-xs;
          font-weight: 500;
          color: rgba(black, 0.5);
        }
      }
    }
  }
}

// API key ----
.APIKey {
  textarea {
    width: 0;
    height: 0;
    opacity: 0;
    border: none;
    color: transparent;
    background: transparent;
  }

  i {
    cursor: pointer;
    margin-left: 0.5rem;

    &:hover {
      &::before {
        color: $color-primary;
      }
    }
  }
}

// Subscription ----
.Subscription {
  .Plan {
    display: flex;
    align-items: center;

    &Cycle {
      display: inline-block;
      margin-left: 0.625rem;
      padding: 0.25rem 0.5rem;
      font-size: 0.625rem;
      border-radius: 1rem;
      font-weight: $font-weight-semibold;
      text-transform: uppercase;
      color: $color-text-muted;
      background-color: $color-background-muted;
    }
  }

  .Schedule {
    display: block;
    margin-top: 0.5rem;
    font-size: $font-size-s;
    color: $color-text-muted;
  }

  .ManageBilling,
  .ChangePlan {
    display: inline-block;
    margin-top: 0.5rem;
    font-size: $font-size-s;
    font-weight: 500;
  }

  .Divider {
    display: inline-block;
    margin: 0 0.5rem;
    color: $color-separator;
  }
}
