@import "~src/ui/constant";

// Plan features ----
.Plan {
  &Feature {
    &List {
      margin: 1.5rem 0 0;
      padding: 0;
    }

    &Item {
      list-style: none;
      margin: 0.5rem 0;

      &::before {
        margin-right: 0.75rem;
        color: $color-icon;
      }

      &Unavailable {
        &::before {
          color: $color-icon-muted;
        }
      }
    }
  }
}
