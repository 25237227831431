// Color ----
$color-primary: #3b82f6;
$color-primary-dark: #0526e6;
$color-primary-light: #96c6fb;
$color-primary-muted: lighten($color-primary, 35%);

$color-secondary: #f6af3b;
$color-secondary-dark: #e65605;
$color-secondary-light: #fbdd96;

$color-dark-gray: #757575;
$color-dark-gray-dark: #212121;
$color-dark-gray-light: #bdbdbd;

$color-light-gray: #f5f5f5;
$color-light-gray-dark: #eeeeee;
$color-light-gray-light: #fafafa;

$color-black: #000000;
$color-white: #ffffff;

$color-success: #28a745;
$color-warning: #ffc107;
$color-danger: #dc3545;

$color-text: $color-dark-gray-dark;
$color-text-muted: $color-dark-gray;

$color-link: $color-primary;

$color-icon: $color-secondary;
$color-icon-muted: $color-dark-gray-light;

$color-background: $color-dark-gray-dark;
$color-background-muted: $color-light-gray;

$color-separator: $color-light-gray-dark;
