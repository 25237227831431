// Buttons ----
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:focus,
  &:active,
  &.active {
    outline: none !important;
    box-shadow: none !important;
    text-decoration: none !important;
  }

  .spinner {
    &-border {
      margin-right: 0.5rem;
      border-width: 0.125rem;
      width: $btn-font-size;
      height: $btn-font-size;
    }
  }

  &-primary {
    color: $color-white;

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: $color-white;
    }

    &:focus,
    &.active,
    &.active {
      background-color: $color-primary;
    }

    &:disabled,
    &.disabled {
      color: lighten($color-primary, 25%);
    }

    .spinner {
      &-border {
        color: $color-white;
      }
    }
  }

  &-outline-primary {
    color: $color-primary;
    background-color: $color-white;

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: $color-primary;
      background-color: $color-primary-muted;
    }

    &:focus,
    &.active,
    &.active {
      background-color: $color-white;
    }

    .spinner {
      &-border {
        color: $color-primary;
      }
    }
  }

  &:disabled,
  &.disabled {
    .spinner {
      &-border {
        opacity: $btn-disabled-opacity;
      }
    }
  }
}
