@import "~src/ui/constant";

// Menu ----
.Menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;

  // Avatar ----
  &Avatar {
    cursor: pointer;

    img {
      width: auto;
      max-height: 2rem;
      border-radius: 2rem;
    }
  }

  // Dropdown ----
  &Dropdown {
    display: none;
    position: absolute;
    top: 2rem + 1rem;
    z-index: 99;
    padding: 1rem 1.25rem;
    border: 1px solid $color-light-gray-dark;
    background: $color-white;
    box-shadow: 0 2.5rem 5rem rgba($color-dark-gray, 25%);
  }

  // User ----
  &User {
    margin-bottom: 1rem;

    &Heading {
      margin-bottom: 0.25rem;
      font-size: $font-size-s;
      color: $color-text-muted;
    }

    &Email {
      font-size: $font-size-s;
    }
  }

  // List ----
  &List {
    margin: 0;
    padding: 0;
  }

  // Item ----
  &Item {
    list-style: none;
    white-space: nowrap;
    line-height: 2;
    font-weight: $font-weight-medium;

    &::before,
    a {
      color: $color-text;
      transition: 0.15s ease-in-out;
    }

    &::before {
      margin-right: 0.75rem;
    }

    &:hover,
    &:focus,
    &:active {
      &::before,
      a {
        color: $color-link;
        text-decoration: none;
      }
    }
  }

  // Expanded menu ----
  &:global(.Expanded) {
    .Menu {
      &Dropdown {
        display: block;
      }
    }
  }
}
