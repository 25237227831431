@import "~src/ui/constant";

// Colors ----
$primary: $color-primary;
$secondary: $color-secondary;

$body-color: $color-text;
$body-bg: $color-white;

// Link ----
$link-color: $color-primary;
$link-decoration: none;

$link-hover-color: $color-primary;
$link-hover-decoration: underline;

// Border ----
$border-color: $color-separator;
$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;

// Buttons ----
$btn-border-width: 2px;
$btn-font-weight: $font-weight-semibold;
$btn-padding-x: 1.5rem;
$btn-padding-x-sm: 1.5rem;
$btn-padding-x-lg: 1.75rem;
$btn-padding-y: 1rem;
$btn-padding-y-sm: 1rem;
$btn-padding-y-lg: 1.25rem;

// Form ----
$form-label-font-weight: $font-weight-medium;
$input-padding-x: 1rem;
$input-padding-y: 1rem;
$input-border-width: 0.125rem;
$input-color: $color-text;
$input-bg: $color-white;
$input-border-color: $color-separator;
$input-placeholder-color: $color-text-muted;
$input-focus-box-shadow: none;
$input-focus-color: $color-text;
$input-focus-bg: $color-white;
$input-focus-border-color: $color-primary;

// Card ----
$card-title-spacer-y: 1.5rem;
