@import "~src/ui/constant";

// Back ----
.Back {
  display: inline-flex;
  align-items: center;
  transition: 0.15s;
  margin-bottom: 1.5rem;
  color: $color-text-muted;
  font-weight: $font-weight-medium;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: $color-text;
  }

  span {
    margin-left: 0.5rem;
  }
}
