@import "~src/ui/constant";

// Quota ----
.Quota {
  flex-grow: 1;
}

// Chart ----
.Chart {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

// Graph ----
.Graph {
  width: 10rem;
}

// Legend list ----
.LegendList {
  margin-left: 1.5rem;
}

// Legend item ----
.LegendItem {
  margin: 0.5rem 0;
  font-size: $font-size-xs;
  font-weight: 500;

  &::before {
    margin-right: 0.5rem;
  }
}

// Used ----
.Used {
  &::before {
    color: transparentize($color-primary, 0.25);
  }
}

// Remaining ----
.Remaining {
  &::before {
    color: $color-primary;
  }
}

// Mobile ----
@media (max-width: $breakpoint-md-down) {
  // Quota ----
  .Quota {
    margin-top: $spacing-gutter;
  }
}
