// Font ----
$font-weight-thin: 100;
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-normal: 400; // Available
$font-weight-medium: 500; // Available
$font-weight-semibold: 600; // Available
$font-weight-bold: 700; // Available
$font-weight-extra-bold: 800;
$font-weight-black: 900;

$font-size-4xl: 3.75rem; // 60
$font-size-3xl: 3rem; // 48
$font-size-2xl: 2.25rem; // 36
$font-size-xl: 1.875rem; // 30
$font-size-l: 1.5rem; // 24
$font-size-m: 1.125rem; // 18
$font-size-r: 1rem; // 16
$font-size-s: 0.875rem; // 14
$font-size-xs: 0.75rem; // 12
