@import "~src/ui/constant";

// Subscribe ----
.Subscribe {
  .Plan {
    &Group {
      display: flex;
      margin: 0 0 2rem 0;
      padding: 0;

      &Tab {
        list-style: none;
        cursor: pointer;
        margin-right: 1.5rem;
        padding: 0.25rem 0;
        border-bottom: 0.1875rem solid transparent;
        color: $color-text-muted;
        font-weight: $font-weight-medium;

        &Active {
          transition: 0.2s;
          color: $color-text;
          border-bottom-color: $color-primary-light;
        }
      }
    }

    &List {
      margin: 0;
      padding: 0;

      @media (min-width: $breakpoint-md) {
        display: flex;
        flex-wrap: wrap;
        margin: -1rem;
      }
    }

    &Item {
      list-style: none;

      @media (min-width: $breakpoint-md) {
        width: 25%;
      }

      &Wrapper {
        margin: 1rem 0;
        transition: 0.4s;

        @media (min-width: $breakpoint-md) {
          margin: 1rem;
        }

        &:hover {
          margin-top: 0.5rem;
          margin-bottom: 1.5rem;
          box-shadow: 0 2.5rem 5rem rgba($color-dark-gray, 25%);
        }
      }
    }
  }
}
