@import "~src/ui/constant";

// Plan ----
.Plan {
  padding: 1.5rem;
  background: $color-white;
  border: 1px solid $color-separator;

  &Heading {
    font-size: $font-size-m;
    font-weight: $font-weight-semibold;
  }

  &RequestCount {
    margin-top: 0.5rem;
    color: $color-text-muted;
    font-weight: $font-weight-medium;
  }

  &Price {
    margin-top: 0.5rem;

    &Amount {
      font-size: $font-size-2xl;
      font-weight: $font-weight-bold;
    }

    &Cycle {
      margin-left: 0.25rem;
      font-size: $font-size-m;
      font-weight: $font-weight-medium;
      color: $color-text-muted;
    }
  }

  &Description {
    margin-top: 1rem;
    color: $color-text-muted;

    span {
      font-weight: $font-weight-medium;
    }
  }

  &Alert {
    margin-top: 1rem;
    margin-bottom: -0.5rem !important;
  }

  &Button {
    width: 100%;
    margin-top: 1.5rem;
  }
}
